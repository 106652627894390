/* TODO: REMOVE .wb-grid-row BEFORE MERGE */
.wb-grid-row {
  display: grid !important;
}

*, :after, :before {
  box-sizing: border-box
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%
}

body {
  margin: 0;
  padding: 0
}

summary {
  list-style-type: none
}

fieldset {
  border: none
}

fieldset, legend {
  padding: 0
}

button {
  margin: 0
}

.wb-skip-link {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 1px
}

.wb-skip-link:focus {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  clip: auto;
  color: currentColor;
  display: inline-block;
  font-family: var(--wb-font-text-bold);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: .0125em;
  line-height: 1.5rem;
  margin: var(--wb-spacing-3xs);
  outline: 1px solid;
  padding: var(--wb-spacing-3xs);
  width: auto
}

.wb-skip-link:focus sup {
  font-size: max(10px, min(.6em, 16px));
  line-height: 0
}

.wb-skip-link:focus sub {
  font-size: max(.45em, min(.7em, 14px));
  line-height: 0
}

.wb-scroll-lock {
  overflow-y: hidden;
  position: fixed;
  width: 100%
}

@font-face {
  font-display: swap;
  font-family: MBCorpo Title;
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF2/MBCorpoATitleCond-Regular-Web.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: MBCorpo Text;
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF2/MBCorpoSText-Regular-Web.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: MBCorpo Text;
  font-style: normal;
  font-weight: 700;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF2/MBCorpoSText-Bold-Web.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: DaimlerCAC-Regular;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF2/DaimlerCAC-Regular.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: DaimlerCS-Regular;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF2/DaimlerCS-Regular.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: DaimlerCACArab-Regular;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF2/DaimlerCACArab-Regular.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: DaimlerCSArab-Regular;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF2/DaimlerCSArab-Regular.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: Hanyi;
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF/Hanyi.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: Hanyi-Ext;
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.oneweb.mercedes-benz.com/plugin/workbench/fonts/2.1.0/WOFF/Hanyi-Ext.woff) format("woff")
}

:host, :root {
  --wb-grid-gutter-width: 0.5rem;
  --wb-grid-content-width: 90vw;
  --wb-grid-content-width: 90%;
  --wb-spacing-3xs: 0.25rem;
  --wb-spacing-xxs: 0.5rem;
  --wb-spacing-xs: 1rem;
  --wb-spacing-s: 1.5rem;
  --wb-spacing-m: 2rem;
  --wb-spacing-l: 3rem;
  --wb-spacing-xl: 4rem;
  --wb-spacing-xxl: 5rem;
  --wb-black: #000;
  --wb-white: #fff;
  --wb-blue-05: #00182c;
  --wb-blue-10: #002441;
  --wb-blue-15: #003156;
  --wb-blue-20: #013c6b;
  --wb-blue-25: #014880;
  --wb-blue-30: #025497;
  --wb-blue-35: #0260ab;
  --wb-blue-40: #036dc1;
  --wb-blue-45: #0078d6;
  --wb-blue-50: #008dfc;
  --wb-blue-55: #1998fc;
  --wb-blue-60: #33a4fd;
  --wb-blue-65: #4eaffd;
  --wb-blue-70: #66bbfd;
  --wb-blue-75: #80c6ff;
  --wb-blue-80: #9ad2fe;
  --wb-blue-85: #b4ddfe;
  --wb-blue-90: #cce8ff;
  --wb-blue-95: #e6f5ff;
  --wb-green-05: #051a07;
  --wb-green-10: #07260b;
  --wb-green-15: #0a330f;
  --wb-green-20: #0d4013;
  --wb-green-25: #0f4d16;
  --wb-green-30: #125a1a;
  --wb-green-35: #14661e;
  --wb-green-40: #177321;
  --wb-green-45: #198025;
  --wb-green-50: #21a330;
  --wb-green-55: #37ac45;
  --wb-green-60: #4db559;
  --wb-green-65: #64bf6e;
  --wb-green-70: #7ac883;
  --wb-green-75: #90d198;
  --wb-green-80: #a6daac;
  --wb-green-85: #bce3c1;
  --wb-green-90: #d3edd6;
  --wb-green-95: #e9f6ea;
  --wb-grey-05: #0d0d0d;
  --wb-grey-10: #1a1a1a;
  --wb-grey-15: #262626;
  --wb-grey-20: #333;
  --wb-grey-25: #424242;
  --wb-grey-30: #4f4f4f;
  --wb-grey-35: #5c5c5c;
  --wb-grey-40: #696969;
  --wb-grey-45: #767676;
  --wb-grey-50: #848484;
  --wb-grey-55: #919191;
  --wb-grey-60: #9f9f9f;
  --wb-grey-65: #adadad;
  --wb-grey-70: #bbb;
  --wb-grey-75: #c9c9c9;
  --wb-grey-80: #dbdbdb;
  --wb-grey-85: #e8e8e8;
  --wb-grey-90: #f4f4f4;
  --wb-grey-95: #f8f8f8;
  --wb-red-05: #2b0707;
  --wb-red-10: #410a0a;
  --wb-red-15: #570d0d;
  --wb-red-20: #6d1111;
  --wb-red-25: #821414;
  --wb-red-30: #981717;
  --wb-red-35: #ae1a1a;
  --wb-red-40: #c31e1e;
  --wb-red-45: #d92121;
  --wb-red-50: #ff4a4a;
  --wb-red-55: #ff5c5c;
  --wb-red-60: #ff6e6e;
  --wb-red-65: #ff8080;
  --wb-red-70: #ff9292;
  --wb-red-75: #ffa5a5;
  --wb-red-80: #ffb7b7;
  --wb-red-85: #ffc9c9;
  --wb-red-90: #ffdbdb;
  --wb-red-95: #ffeded;
  --wb-yellow-05: #2e2600;
  --wb-yellow-10: #463900;
  --wb-yellow-15: #5d4c00;
  --wb-yellow-20: #745f00;
  --wb-yellow-25: #8b7100;
  --wb-yellow-30: #a28400;
  --wb-yellow-35: #ba9700;
  --wb-yellow-40: #d1aa00;
  --wb-yellow-45: #e8bd00;
  --wb-yellow-50: #facc00;
  --wb-yellow-55: #fbd11a;
  --wb-yellow-60: #fbd633;
  --wb-yellow-65: #fcdb4c;
  --wb-yellow-70: #fce066;
  --wb-yellow-75: #fde680;
  --wb-yellow-80: #fdeb99;
  --wb-yellow-85: #fef0b3;
  --wb-yellow-90: #fef5cc;
  --wb-yellow-95: #fffae6;
  --wb-font-title: "MBCorpo Title", "DaimlerCAC-Regular", "DaimlerCACArab-Regular", serif;
  --wb-font-text: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  --wb-font-text-bold: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  --wb-shadow-s: 0 0 2px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(0, 0, 0, .1);
  --wb-shadow-m: 0 0 2px 0 rgba(0, 0, 0, .1), 0 4px 8px 0 rgba(0, 0, 0, .1);
  --wb-shadow-l: 0 0 4px 0 rgba(0, 0, 0, .1), 0 16px 24px 0 rgba(0, 0, 0, .1);
  --wb-fade: cubic-bezier(0, 0, 0.3, 1);
  --wb-move: cubic-bezier(0.3, 0, 0, 1);
  --wb-open: cubic-bezier(0.25, 0.1, 0.25, 1);
  --wb-radius-s: 2px;
  --wb-radius-m: 4px;
  --wb-radius-l: 8px;
  --wb-radius-full: 50%;
  --wb-grid-width: min(var(--wb-grid-content-width), 1680px);
  --wb-grid-column-width: min(calc((var(--wb-grid-content-width) - 11 * var(--wb-grid-gutter-width)) / 12), 110.6666666667px)
}

@media (min-width: 48em) {
  :host, :root {
    --wb-grid-gutter-width: 1rem;
    --wb-grid-content-width: 86vw;
    --wb-grid-content-width: 86%
  }
}

@media (min-width: 64em) {
  :host, :root {
    --wb-spacing-xs: 1.5rem;
    --wb-spacing-s: 2rem;
    --wb-spacing-m: 3rem;
    --wb-spacing-l: 4rem;
    --wb-spacing-xl: 5rem;
    --wb-spacing-xxl: 5.5rem
  }
}

@media (min-width: 80em) {
  :host, :root {
    --wb-grid-gutter-width: 1.5rem
  }
}

@media (min-width: 90em) {
  :host, :root {
    --wb-grid-gutter-width: 2rem;
    --wb-spacing-l: 4.5rem;
    --wb-spacing-xl: 5.5rem;
    --wb-spacing-xxl: 6rem
  }
}

:not(:defined) {
  visibility: hidden
}

.wb-margin-top-3xs {
  -webkit-margin-before: var(--wb-spacing-3xs);
  margin-block-start: var(--wb-spacing-3xs)
}

.wb-margin-bottom-3xs {
  -webkit-margin-after: var(--wb-spacing-3xs);
  margin-block-end: var(--wb-spacing-3xs)
}

.wb-margin-start-3xs {
  -webkit-margin-start: var(--wb-spacing-3xs);
  margin-inline-start: var(--wb-spacing-3xs)
}

.wb-margin-end-3xs {
  -webkit-margin-end: var(--wb-spacing-3xs);
  margin-inline-end: var(--wb-spacing-3xs)
}

.wb-margin-horizontal-3xs {
  margin-inline: var(--wb-spacing-3xs)
}

.wb-margin-vertical-3xs {
  margin-block: var(--wb-spacing-3xs)
}

.wb-margin-3xs {
  margin: var(--wb-spacing-3xs)
}

.wb-padding-top-3xs {
  -webkit-padding-before: var(--wb-spacing-3xs);
  padding-block-start: var(--wb-spacing-3xs)
}

.wb-padding-bottom-3xs {
  -webkit-padding-after: var(--wb-spacing-3xs);
  padding-block-end: var(--wb-spacing-3xs)
}

.wb-padding-start-3xs {
  -webkit-padding-start: var(--wb-spacing-3xs);
  padding-inline-start: var(--wb-spacing-3xs)
}

.wb-padding-end-3xs {
  -webkit-padding-end: var(--wb-spacing-3xs);
  padding-inline-end: var(--wb-spacing-3xs)
}

.wb-padding-horizontal-3xs {
  padding-inline: var(--wb-spacing-3xs)
}

.wb-padding-vertical-3xs {
  padding-block: var(--wb-spacing-3xs)
}

.wb-padding-3xs {
  padding: var(--wb-spacing-3xs)
}

.wb-margin-top-xxs {
  -webkit-margin-before: var(--wb-spacing-xxs);
  margin-block-start: var(--wb-spacing-xxs)
}

.wb-margin-bottom-xxs {
  -webkit-margin-after: var(--wb-spacing-xxs);
  margin-block-end: var(--wb-spacing-xxs)
}

.wb-margin-start-xxs {
  -webkit-margin-start: var(--wb-spacing-xxs);
  margin-inline-start: var(--wb-spacing-xxs)
}

.wb-margin-end-xxs {
  -webkit-margin-end: var(--wb-spacing-xxs);
  margin-inline-end: var(--wb-spacing-xxs)
}

.wb-margin-horizontal-xxs {
  margin-inline: var(--wb-spacing-xxs)
}

.wb-margin-vertical-xxs {
  margin-block: var(--wb-spacing-xxs)
}

.wb-margin-xxs {
  margin: var(--wb-spacing-xxs)
}

.wb-padding-top-xxs {
  -webkit-padding-before: var(--wb-spacing-xxs);
  padding-block-start: var(--wb-spacing-xxs)
}

.wb-padding-bottom-xxs {
  -webkit-padding-after: var(--wb-spacing-xxs);
  padding-block-end: var(--wb-spacing-xxs)
}

.wb-padding-start-xxs {
  -webkit-padding-start: var(--wb-spacing-xxs);
  padding-inline-start: var(--wb-spacing-xxs)
}

.wb-padding-end-xxs {
  -webkit-padding-end: var(--wb-spacing-xxs);
  padding-inline-end: var(--wb-spacing-xxs)
}

.wb-padding-horizontal-xxs {
  padding-inline: var(--wb-spacing-xxs)
}

.wb-padding-vertical-xxs {
  padding-block: var(--wb-spacing-xxs)
}

.wb-padding-xxs {
  padding: var(--wb-spacing-xxs)
}

.wb-margin-top-xs {
  -webkit-margin-before: var(--wb-spacing-xs);
  margin-block-start: var(--wb-spacing-xs)
}

.wb-margin-bottom-xs {
  -webkit-margin-after: var(--wb-spacing-xs);
  margin-block-end: var(--wb-spacing-xs)
}

.wb-margin-start-xs {
  -webkit-margin-start: var(--wb-spacing-xs);
  margin-inline-start: var(--wb-spacing-xs)
}

.wb-margin-end-xs {
  -webkit-margin-end: var(--wb-spacing-xs);
  margin-inline-end: var(--wb-spacing-xs)
}

.wb-margin-horizontal-xs {
  margin-inline: var(--wb-spacing-xs)
}

.wb-margin-vertical-xs {
  margin-block: var(--wb-spacing-xs)
}

.wb-margin-xs {
  margin: var(--wb-spacing-xs)
}

.wb-padding-top-xs {
  -webkit-padding-before: var(--wb-spacing-xs);
  padding-block-start: var(--wb-spacing-xs)
}

.wb-padding-bottom-xs {
  -webkit-padding-after: var(--wb-spacing-xs);
  padding-block-end: var(--wb-spacing-xs)
}

.wb-padding-start-xs {
  -webkit-padding-start: var(--wb-spacing-xs);
  padding-inline-start: var(--wb-spacing-xs)
}

.wb-padding-end-xs {
  -webkit-padding-end: var(--wb-spacing-xs);
  padding-inline-end: var(--wb-spacing-xs)
}

.wb-padding-horizontal-xs {
  padding-inline: var(--wb-spacing-xs)
}

.wb-padding-vertical-xs {
  padding-block: var(--wb-spacing-xs)
}

.wb-padding-xs {
  padding: var(--wb-spacing-xs)
}

.wb-margin-top-s {
  -webkit-margin-before: var(--wb-spacing-s);
  margin-block-start: var(--wb-spacing-s)
}

.wb-margin-bottom-s {
  -webkit-margin-after: var(--wb-spacing-s);
  margin-block-end: var(--wb-spacing-s)
}

.wb-margin-start-s {
  -webkit-margin-start: var(--wb-spacing-s);
  margin-inline-start: var(--wb-spacing-s)
}

.wb-margin-end-s {
  -webkit-margin-end: var(--wb-spacing-s);
  margin-inline-end: var(--wb-spacing-s)
}

.wb-margin-horizontal-s {
  margin-inline: var(--wb-spacing-s)
}

.wb-margin-vertical-s {
  margin-block: var(--wb-spacing-s)
}

.wb-margin-s {
  margin: var(--wb-spacing-s)
}

.wb-padding-top-s {
  -webkit-padding-before: var(--wb-spacing-s);
  padding-block-start: var(--wb-spacing-s)
}

.wb-padding-bottom-s {
  -webkit-padding-after: var(--wb-spacing-s);
  padding-block-end: var(--wb-spacing-s)
}

.wb-padding-start-s {
  -webkit-padding-start: var(--wb-spacing-s);
  padding-inline-start: var(--wb-spacing-s)
}

.wb-padding-end-s {
  -webkit-padding-end: var(--wb-spacing-s);
  padding-inline-end: var(--wb-spacing-s)
}

.wb-padding-horizontal-s {
  padding-inline: var(--wb-spacing-s)
}

.wb-padding-vertical-s {
  padding-block: var(--wb-spacing-s)
}

.wb-padding-s {
  padding: var(--wb-spacing-s)
}

.wb-margin-top-m {
  -webkit-margin-before: var(--wb-spacing-m);
  margin-block-start: var(--wb-spacing-m)
}

.wb-margin-bottom-m {
  -webkit-margin-after: var(--wb-spacing-m);
  margin-block-end: var(--wb-spacing-m)
}

.wb-margin-start-m {
  -webkit-margin-start: var(--wb-spacing-m);
  margin-inline-start: var(--wb-spacing-m)
}

.wb-margin-end-m {
  -webkit-margin-end: var(--wb-spacing-m);
  margin-inline-end: var(--wb-spacing-m)
}

.wb-margin-horizontal-m {
  margin-inline: var(--wb-spacing-m)
}

.wb-margin-vertical-m {
  margin-block: var(--wb-spacing-m)
}

.wb-margin-m {
  margin: var(--wb-spacing-m)
}

.wb-padding-top-m {
  -webkit-padding-before: var(--wb-spacing-m);
  padding-block-start: var(--wb-spacing-m)
}

.wb-padding-bottom-m {
  -webkit-padding-after: var(--wb-spacing-m);
  padding-block-end: var(--wb-spacing-m)
}

.wb-padding-start-m {
  -webkit-padding-start: var(--wb-spacing-m);
  padding-inline-start: var(--wb-spacing-m)
}

.wb-padding-end-m {
  -webkit-padding-end: var(--wb-spacing-m);
  padding-inline-end: var(--wb-spacing-m)
}

.wb-padding-horizontal-m {
  padding-inline: var(--wb-spacing-m)
}

.wb-padding-vertical-m {
  padding-block: var(--wb-spacing-m)
}

.wb-padding-m {
  padding: var(--wb-spacing-m)
}

.wb-margin-top-l {
  -webkit-margin-before: var(--wb-spacing-l);
  margin-block-start: var(--wb-spacing-l)
}

.wb-margin-bottom-l {
  -webkit-margin-after: var(--wb-spacing-l);
  margin-block-end: var(--wb-spacing-l)
}

.wb-margin-start-l {
  -webkit-margin-start: var(--wb-spacing-l);
  margin-inline-start: var(--wb-spacing-l)
}

.wb-margin-end-l {
  -webkit-margin-end: var(--wb-spacing-l);
  margin-inline-end: var(--wb-spacing-l)
}

.wb-margin-horizontal-l {
  margin-inline: var(--wb-spacing-l)
}

.wb-margin-vertical-l {
  margin-block: var(--wb-spacing-l)
}

.wb-margin-l {
  margin: var(--wb-spacing-l)
}

.wb-padding-top-l {
  -webkit-padding-before: var(--wb-spacing-l);
  padding-block-start: var(--wb-spacing-l)
}

.wb-padding-bottom-l {
  -webkit-padding-after: var(--wb-spacing-l);
  padding-block-end: var(--wb-spacing-l)
}

.wb-padding-start-l {
  -webkit-padding-start: var(--wb-spacing-l);
  padding-inline-start: var(--wb-spacing-l)
}

.wb-padding-end-l {
  -webkit-padding-end: var(--wb-spacing-l);
  padding-inline-end: var(--wb-spacing-l)
}

.wb-padding-horizontal-l {
  padding-inline: var(--wb-spacing-l)
}

.wb-padding-vertical-l {
  padding-block: var(--wb-spacing-l)
}

.wb-padding-l {
  padding: var(--wb-spacing-l)
}

.wb-margin-top-xl {
  -webkit-margin-before: var(--wb-spacing-xl);
  margin-block-start: var(--wb-spacing-xl)
}

.wb-margin-bottom-xl {
  -webkit-margin-after: var(--wb-spacing-xl);
  margin-block-end: var(--wb-spacing-xl)
}

.wb-margin-start-xl {
  -webkit-margin-start: var(--wb-spacing-xl);
  margin-inline-start: var(--wb-spacing-xl)
}

.wb-margin-end-xl {
  -webkit-margin-end: var(--wb-spacing-xl);
  margin-inline-end: var(--wb-spacing-xl)
}

.wb-margin-horizontal-xl {
  margin-inline: var(--wb-spacing-xl)
}

.wb-margin-vertical-xl {
  margin-block: var(--wb-spacing-xl)
}

.wb-margin-xl {
  margin: var(--wb-spacing-xl)
}

.wb-padding-top-xl {
  -webkit-padding-before: var(--wb-spacing-xl);
  padding-block-start: var(--wb-spacing-xl)
}

.wb-padding-bottom-xl {
  -webkit-padding-after: var(--wb-spacing-xl);
  padding-block-end: var(--wb-spacing-xl)
}

.wb-padding-start-xl {
  -webkit-padding-start: var(--wb-spacing-xl);
  padding-inline-start: var(--wb-spacing-xl)
}

.wb-padding-end-xl {
  -webkit-padding-end: var(--wb-spacing-xl);
  padding-inline-end: var(--wb-spacing-xl)
}

.wb-padding-horizontal-xl {
  padding-inline: var(--wb-spacing-xl)
}

.wb-padding-vertical-xl {
  padding-block: var(--wb-spacing-xl)
}

.wb-padding-xl {
  padding: var(--wb-spacing-xl)
}

.wb-margin-top-xxl {
  -webkit-margin-before: var(--wb-spacing-xxl);
  margin-block-start: var(--wb-spacing-xxl)
}

.wb-margin-bottom-xxl {
  -webkit-margin-after: var(--wb-spacing-xxl);
  margin-block-end: var(--wb-spacing-xxl)
}

.wb-margin-start-xxl {
  -webkit-margin-start: var(--wb-spacing-xxl);
  margin-inline-start: var(--wb-spacing-xxl)
}

.wb-margin-end-xxl {
  -webkit-margin-end: var(--wb-spacing-xxl);
  margin-inline-end: var(--wb-spacing-xxl)
}

.wb-margin-horizontal-xxl {
  margin-inline: var(--wb-spacing-xxl)
}

.wb-margin-vertical-xxl {
  margin-block: var(--wb-spacing-xxl)
}

.wb-margin-xxl {
  margin: var(--wb-spacing-xxl)
}

.wb-padding-top-xxl {
  -webkit-padding-before: var(--wb-spacing-xxl);
  padding-block-start: var(--wb-spacing-xxl)
}

.wb-padding-bottom-xxl {
  -webkit-padding-after: var(--wb-spacing-xxl);
  padding-block-end: var(--wb-spacing-xxl)
}

.wb-padding-start-xxl {
  -webkit-padding-start: var(--wb-spacing-xxl);
  padding-inline-start: var(--wb-spacing-xxl)
}

.wb-padding-end-xxl {
  -webkit-padding-end: var(--wb-spacing-xxl);
  padding-inline-end: var(--wb-spacing-xxl)
}

.wb-padding-horizontal-xxl {
  padding-inline: var(--wb-spacing-xxl)
}

.wb-padding-vertical-xxl {
  padding-block: var(--wb-spacing-xxl)
}

.wb-padding-xxl {
  padding: var(--wb-spacing-xxl)
}

@media (max-width: 29.99em) {
  .wb-show-from-mq1 {
    display: none
  }
}

@media (min-width: 20em) {
  .wb-show-to-mq1 {
    display: none
  }
}

@media (max-width: 47.99em) {
  .wb-show-from-mq2 {
    display: none
  }
}

@media (min-width: 30em) {
  .wb-show-to-mq2 {
    display: none
  }
}

@media (max-width: 63.99em) {
  .wb-show-from-mq3 {
    display: none
  }
}

@media (min-width: 48em) {
  .wb-show-to-mq3 {
    display: none
  }
}

@media (max-width: 79.99em) {
  .wb-show-from-mq4 {
    display: none
  }
}

@media (min-width: 64em) {
  .wb-show-to-mq4 {
    display: none
  }
}

@media (max-width: 89.99em) {
  .wb-show-from-mq5 {
    display: none
  }
}

@media (min-width: 80em) {
  .wb-show-to-mq5 {
    display: none
  }
}

.wb-show-from-mq6 {
  display: none
}

@media (min-width: 90em) {
  .wb-show-to-mq6 {
    display: none
  }
}
