@import "@workbench/core/dist/scss/utility";
@import "@fit/ui/dist/scss/globals";
@import "@workbench/core/dist/scss/globals";
@import "@workbench/core/dist/scss/extensions/cloak";

:not(:defined) {
  visibility: visible !important; // TODO: WTH? Workbench
}

html {
  background: var(--wb-black);
}

body {
  wb-grid-row {
    --wb-grid-gutter-width: 24px;
    --vertical-grid-gap: 24px;
  }

  .subgrid {
    @include breakpoint-from(mq6) {
      /* to prevent the grid from overflowing as long as subgrid is not widely supported  */
      --wb-grid-gutter-width: 0;
    }
  }
}

body:not(.loader-active__loader_host) {
  color: white;
}

.no-scroll {
  overflow-y: hidden;
  width: 100%;
}

.loader-active__loader_outlet {
  pointer-events: none; // disable click when loader shown
}

.loader-active__loader_outlet tr:not {
  position: relative;
}

.loader-active__loader_host {
  table {
    position: relative;
  }

  .loader-active__loader_component {
    div {
      position: absolute;
    }
  }
}

@import "~@angular/cdk/overlay-prebuilt.css";

@media screen and (min-width: 768px) {
  .overlay-table-action {
    transform: translate(-28px, -70px);
  }
}

@media screen and (max-width: 768px) {
  .overlay-table-action {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.fit-toast-container {
  right: 0;
  top: 115px;
}

body[data-bg="white"] {
  background-color: white;
}

body[data-bg="gray"] {
  background: var(--wb-grey-85);
}

body[data-bg="black"] {
  background: var(--wb-grey-20);
  background: linear-gradient(180deg, #000000 0, #2e2e2e 300px, #000000 1000px);
}

.wb-margin-top-gutter-width {
  margin-top: var(--wb-grid-gutter-width);

  &-to-mq5 {
    @include breakpoint-to(mq5) {
      margin-top: var(--wb-grid-gutter-width);
    }
  }
}

.wb-padding-bottom-gutter-width {
  padding-bottom: var(--wb-grid-gutter-width);

  &-to-mq5 {
    @include breakpoint-to(mq5) {
      padding-bottom: var(--wb-grid-gutter-width);
    }
  }
}
